import * as Sentry from "@sentry/node";
import { AppProps } from "next/app";
import { analytics, theme, BackToTop } from "@project/shared";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { BottomLinks, Footer, Header } from "../components";
import { Layout, message } from "antd";
import { useRouter } from "next/router";
import "antd/dist/antd.css";
import "../globalStyles/index.css";
import Head from "next/head";
import {
  UseFavCount,
  PetContext,
  QueryContext,
  SearchQueryContext,
  useQuery,
  useSearchQuery,
} from "../components/molecules";
import BrowserNotSupported from "./browser-not-supported";
import { useLoadScript } from "@react-google-maps/api";
import { QueryClient, QueryClientProvider } from "react-query";

const Wrapper = styled(Layout)`
  width: 100%;
  background-color: ${theme.base};
`;

declare global {
  interface Document {
    documentMode?: any;
  }
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `kowapets-frontend-user-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

message.config({
  duration: 4.5,
  maxCount: 3,
});

const queryClient = new QueryClient({ defaultOptions: {} });
const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter();
  const favCount = UseFavCount();
  const query = useQuery();
  const searchquery = useSearchQuery();
  const [isIE, setIsIE] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAGMsRhRoQFeExZ06Gob4fGMoEhqBhuDUs",
  });

  useEffect(() => {
    const logEvent = (url: string) => {
      analytics().setCurrentScreen(url);
    };

    routers.events.on("routeChangeComplete", (url) => {
      if (url != "/") {
        window.scrollTo(0, 0);
      }
      logEvent(url);
    });

    logEvent(window.location.pathname);
    return () => {
      routers.events.off("routeChangeComplete", logEvent);
    };
  }, []);

  useEffect(() => {
    const isIE = !!window.document.documentMode;
    if (isIE) {
      setIsIE(true);
    }
  }, []);

  if (isIE) {
    return <BrowserNotSupported />;
  }

  return (
    <Wrapper>
      <Head>
        <title>
          ワンちゃん・ネコちゃん・観賞魚などペットの販売ならペットショップのコーワペッツ
        </title>
        <meta
          name="description"
          content="ペットショップ コーワペッツ コーポレーション オフィシャルサイトです。関東、中部、東海、近畿、西日本に店舗展開中。子犬、子猫、うさぎ、ハムスター、熱帯魚などのペットを紹介しています。可愛いワンちゃん、ネコちゃん達がお客様のご来店をお待ちしております。"
        />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="ワンちゃん・ネコちゃん・観賞魚などペットの販売ならペットショップのコーワペッツ"
        />
        <meta
          name="twitter:description"
          content="ペットショップ コーワペッツ コーポレーション オフィシャルサイトです。関東、中部、東海、近畿、西日本に店舗展開中。子犬、子猫、うさぎ、ハムスター、熱帯魚などのペットを紹介しています。可愛いワンちゃん、ネコちゃん達がお客様のご来店をお待ちしております。"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
        <script src="https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js" />

        {process.env.NODE_ENV === "production" && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=G-H6PMR1WGFF`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-H6PMR1WGFF');
                `,
              }}
            />
            <script
              type="text/javascript"
              async
              dangerouslySetInnerHTML={{
                __html: `
              (function(w, d, s, l, i) {
                    w[l] = w[l] || []
                    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
                    const f = d.getElementsByTagName(s)[0],
                      j = d.createElement(s),
                      dl = l != "dataLayer" ? "&l=" + l : ""
                    j.async = true
                    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
                    f.parentNode.insertBefore(j, f)
                  })(window,document,'script','dataLayer',"GTM-TWGWVLR")`,
              }}
            />
          </>
        )}
      </Head>
      <QueryClientProvider client={queryClient}>
        <PetContext.Provider value={{ ...favCount, isLoaded }}>
          <QueryContext.Provider value={query}>
            <SearchQueryContext.Provider value={searchquery}>
              <Header />

              <Component {...pageProps} />

              <Footer />
              <BackToTop />
              <BottomLinks />
            </SearchQueryContext.Provider>
          </QueryContext.Provider>
        </PetContext.Provider>
      </QueryClientProvider>
      {/* <!-- Google Tag Manager (noscript) --> */}
      {process.env.NODE_ENV === "production" && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=GTM-TWGWVLR`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      )}
    </Wrapper>
  );
};

export default MyApp;
