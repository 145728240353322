import React, { useEffect } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 550px;
    & p {
      font-weight: normal;
      font-size: 24px;
    }
  }
`;

const BrowserNotSupported = () => {
  const router = useRouter();
  useEffect(() => {
    const isIE = !!window.document.documentMode;
    if (!isIE) {
      router.push("/");
    }
  }, []);
  return (
    <Wrapper>
      <div>
        <p>Your browser is not supported</p>
        <p>
          Unfortunately, your browser is no longer <br /> supported and you will
          not be able to access the website.
        </p>
        <p>Please use a newer browser such as Chrome or Edge Browser.</p>
      </div>
    </Wrapper>
  );
};

export default BrowserNotSupported;
